import { twMerge } from "tailwind-merge";
import { FC, ReactNode } from "react";
import Spinner from "@/components/Spinner/Spinner";
import styles from "@/components/Buttons/Primary/styles.module.css";

type Props = {
  className?: string;
  label: string;
  onClick?: () => void;
  onMouseDown?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  beforeIcon?: ReactNode;
  afterIcon?: ReactNode;
  isShineHover?: boolean;
  noShadow?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
};

const Button: FC<Props> = ({
  className = "",
  label,
  isLoading,
  beforeIcon,
  afterIcon,
  isShineHover = false,
  onClick,
  isDisabled = false,
  noShadow = false,
  type,
  onMouseDown,
}) => {
  return (
    <button
      type={type}
      title={label}
      disabled={isDisabled || isLoading}
      className={twMerge(
        "relative group transition-all flex items-center border-[1px] border-primary justify-center bg-primary text-white py-[16px] px-[32px] gap-[10px] text-[16px] rounded-[12px] font-bold outline-none",
        "md:hover:!bg-primary-400 md:hover:border-primary-400",
        !noShadow && "shadow-[0px_4px_0px_0px_#E90C19]",
        isDisabled &&
          "bg-black-200 border-black-200 text-black-400 md:hover:!border-black-200 md:hover:!bg-black-200 cursor-not-allowed",
        !noShadow && isDisabled && "shadow-[0px_4px_0px_0px_#E7E7E1]",
        isLoading && "cursor-not-allowed",
        isShineHover && styles.shine,
        className,
      )}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {!isLoading && beforeIcon}
      <span className={twMerge("truncate", isLoading && "invisible")}>
        {label}
      </span>
      {!isLoading && afterIcon}
      {isLoading && (
        <Spinner
          className="absolute scale-[0.4] top-[calc(50%-14px)] left-[calc(50%-8px)]"
          innerClassName="text-white"
        />
      )}
    </button>
  );
};

export default Button;
